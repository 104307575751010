#About-Me {
	padding-bottom: 5px;
}

#Name {
	font-family: 'Enriqueta'; 
	line-height: 1.25; 
	margin: 0 0 5px; 
	font-size: 60px; 
	font-weight: bold;
	text-align: center;
}

@media only screen and (max-width: 600px) {
	#Name {
		font-size: 50px;
	}
}

h3 {
	height: 18px;
}

/*iPhone 6/7/8 Problem*/
@media only screen and (max-width: 600px) and (min-height: 650px){
	h3 {
		height: 10px;
	}
}

#profile-pic {
	margin: auto;
	display: block;
	max-width: 1500px;
	width: 100%;
	min-height: 50vh;
	object-fit: cover;
}

.where-i-go {
	font-size: 23px; 
	text-align: center;
}

@media only screen and (max-width: 600px) {
	.where-i-go {
		font-size: 18px;
	}
}

#logos-flexbox {
	display: flex;
	list-style-type: none;
	justify-content: space-around;
	max-width: 300px;
	margin: auto;
	padding: 0;
	
}

.logos-flexbox-item {
	text-align: center;
}

.Social {
	width: 50px;
	height: 50px;
}

@media only screen and (max-width: 600px) {
	.Social {
		width: 40px;
		height: 40px;
	}
}

footer {
	left: 0;
	bottom: 0;
	width: 100%;
	text-align: center;
}

/* iPhone 5/5E Problem */
@media only screen and (max-width: 400px) and (max-height: 600px) {
	h3 {
		height: 10px;
	}
	#Name {
		font-size: 40px;
	}
}
