/*nbout - <div> for whole page*/
#about {
	font-family: 'Open-Sans';
	padding-bottom: 10px;
	font-size: 25px; 
	text-align: center;
}

/*name - <h1> for name*/
#name {
	font-family: 'Enriqueta'; 
	line-height: 1.25; 
	margin-bottom: 10px; 
	font-size: 40px; 
	font-weight: bold;
}

/*mobile phone - reduce size of image*/
@media only screen and (max-width: 600px) {
	#name {
		font-size: 30px;
	}
}

/*about-pic - image*/
#about-pic {
	border-radius: 50%;
	max-width: 350px;
	width: 50%;
}

/*personal-description - <p> for short description*/
#personal-description {
	max-width: 800px;
	line-height: 1.25;
	margin: auto;
	text-align: left;
	color: black;
}

/*mobile phone - reduce size of personal description*/
@media only screen and (max-width: 600px) {
	.below-pic {
		font-size: 20px;
	}
	#personal-description-para {
		font-size: 18px;
	}
}

/*a - hyperlinks to link to different pages */
a {
	text-decoration: none;
	color: #2156a5;
}

/*hr - lines between descriptions*/
hr {
	padding-top: 10px;
	padding-bottom: 10px;
}

/*internships - auto scale*/
#internships {	
	max-width: 1500px;
	margin: auto;
}

/*specific-internship - flexbox*/
.specific-internship {
	display: flex;
	flex-wrap: wrap;
	flex-direction: row;
}

.specific-rev {
	flex-direction: row-reverse;
}

/*logo - (may only apply to GEP logo)*/
.logo {
	width: 350px;
	height: 130px;
	box-shadow: rgba(0,0,0,0.57) -10px 10px 17px 0px;
	border-radius: 7%;
	background-color: #ffffff
}

.logo-rev {
	box-shadow: rgba(0,0,0,0.57) 10px 10px 17px 0px;
}

.logo-div {
	margin-left: auto;
	margin-right: auto;
}

@media only screen and (max-width: 600px) {
	.logo {
		box-shadow: none;
		border-radius: 0%;
		background-color: #deeae9;
	}
	.logo-rev {
		box-shadow: none;
	}
	#gep-logo {
		object-fit: contain;
		max-width: 100%;
	}
	#Title {
		margin-top: 10px;
		font-size: 25px;
	}
}

/*title-description-flexbox - flexbox*/
.title-description-flexbox {
	display: flex;	
	flex-direction: column;
	flex: 1;
	text-align: left;	
	padding-left: 15px;	
}

/*intern-description - internship bullet points*/
.intern-description {
	font-family: 'Lato',sans-serif;
	font-size: 18px;
	color: grey;
}

@media only screen and (max-width: 600px) {
	.intern-description {
		font-size: 15px;
		max-width: 350px;
		margin-bottom: 8px;
	}
}